
<template>
  <div>
    <PageHeader />
    <b-card>
      <ValidationObserver
        ref="userForm"
        v-slot="{ handleSubmit, failed }"
      >
        <b-toast
          :visible="failed"
          title="Warning"
          variant="warning"
        >
          {{ $t('messages.invalid_form') }}
        </b-toast>

        <form @submit.prevent="handleSubmit(onSubmit)">
          <b-row v-if="isOwner || isMaster" class="mb-3">
            <b-col lg="3" sm="12">
              <label class="col-form-label">เลือก เอเย่นต์</label>
            </b-col>

            <b-col v-if="isOwner" cols="3">
              <MasterSelectInput 
                :value="playerData.selectedMaster"
                hide-label
                @update="onMasterIdChange"
              />
            </b-col>
            <b-col v-if="isOwner || isMaster" cols="3">
              <AgentsSelectInput
                :master-id="playerData.selectedMaster"
                :value="playerData.agentId"
                hide-label
                @update="onAgentIdChange"
              />
            </b-col>
          </b-row>


          <ValidationProvider
              v-slot="{ errors }"
              :rules="{
          min: 10,
          regex: /^[0-9]+$/,
        }"
              name="username"
          >
            <b-form-group
                label="ยูสเซอร์เนม (เบอร์)"
                label-cols-lg="3"
                label-cols-sm="12"
                label-for="username"
            >
              <b-form-input
                  id="username"
                  v-model="playerData.username"
                  v-mask="'##########'"
                  :aria-invalid="false"
                  :state="errors[0] ? false : null"
                  aria-describedby="input-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                {{errors[0]}}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('user.password')"
            rules="required"
          >
            <b-form-group
              :label="`${$t('user.password')} *`"
              label-cols-lg="3"
              label-cols-sm="12"
              label-for="agent_password"
            >
              <b-input-group>
                <b-form-input
                  id="agent_password"
                  v-model="playerData.password"
                  :state="errors[0] ? false : null"
                  :type="isShowPassword?'text':'password'"
                  autocomplete="off"
                ></b-form-input>
                <template #append>
                  <b-button @click="isShowPassword = !isShowPassword">
                    <i
                      v-if="isShowPassword"
                      class="uil uil-eye"
                    ></i>
                    <i
                      v-else
                      class="uil uil-eye-slash"
                    ></i>
                  </b-button>
                </template>
              </b-input-group>
            </b-form-group>
          </ValidationProvider>

          <b-form-group
              label="ธนาคาร"
              label-cols-lg="3"
              label-cols-sm="12"
              label-for="bank-code"
          >
            <b-form-select
                v-model="playerData.bankCode"
                :options="bankList"
            >
              <template #first>
                <b-form-select-option disabled value="">
                  {{ `-- ${$t('fields.click_select')} --` }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
              label="เลขบัญชี"
              label-cols-lg="3"
              label-cols-sm="12"
              label-for="bank-account-number"
          >
            <b-form-input
                id="bank-account-number"
                v-model="playerData.bankAccountNumber"
            ></b-form-input>
          </b-form-group>

          <b-form-group
              label="ชื่อ"
              label-cols-lg="3"
              label-cols-sm="12"
              label-for="first-name"
          >
            <b-form-input
                id="first-name"
                v-model="playerData.firstName"
            ></b-form-input>
          </b-form-group>

          <b-form-group
              label="นามสกุล"
              label-cols-lg="3"
              label-cols-sm="12"
              label-for="last-name"
          >
            <b-form-input
                id="last-name"
                v-model="playerData.lastName"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="ไลน์"
            label-cols-lg="3"
            label-cols-sm="12"
            label-for="line-id"
          >
            <b-form-input
              id="line-id"
              v-model="playerData.lineId"
            ></b-form-input>
          </b-form-group>

          <div class="text-right">
            <b-button
              class="mr-2"
              to="/players/list"
              variant="light"
            >
              {{ $t('buttons.back')}}
            </b-button>
            <b-overlay
              class="d-inline-block"
              rounded
              spinner-small
            >
              <b-button
                :disabled="isCreatingPlayer"
                block
                type="submit"
                variant="primary"
              >
                {{ $t('buttons.save') }}
              </b-button>
            </b-overlay>
          </div>
        </form>
      </ValidationObserver>
    </b-card>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {banks} from '@utils/data-mock';
import {BankCodeEnum} from '@src/constants/bank';

export default {
  page: {
    title: 'เพิ่มผู้เล่นใหม่',
  },
    components: {
    MasterSelectInput: () =>
      import('@components/master/master-select-input'),
    AgentsSelectInput: () =>
      import('@components/agents/agents-select-input'),
  },
  data() {
    return {
      bankList: banks
        .filter((bank) => process.env.VUE_APP_IS_LA === 'true' || bank.bank_code !== BankCodeEnum.BCEL)
        .map((bank) => {
          return {
            value: bank.bank_code,
            text: bank.bank_name_th
          }
        }),
      isShowPassword: false,
      playerData: {
        username: '',
        password: '',
        bankAccountNumber: '',
        firstName: '',
        lastName: '',
        bankCode: null,
        lineId: '',
        registerFrom: 'other',
        selectedMaster: '',
        agentId: null,
      },
    }
  },
  computed: {
    ...mapState({
      isCreatingPlayer: (state) =>
          state.player.isCreatingPlayer,
    }),
    ...mapGetters(['isOwner', 'isMaster', 'userBankAccounts']),

  },
  methods: {
    ...mapActions(['createPlayer']),
    onSubmit() {
      this.createPlayer(this.playerData)
    },
    onAgentIdChange(agentId) {
      this.playerData.agentId = agentId
    },
    onMasterIdChange(masterId) {
      this.playerData.selectedMaster = masterId
    },
  },
}
</script>
